import React from "react";

import {
  GTBA_DOCUMENT,
  GTBAG_DOCUMENT,
  TBA_DOCUMENT,
} from "@/utils/documentGroups";
import useRichText from "@/utils/hooks/graphql/useRichText";
import usePropertyIdAndValuationYear from "@/utils/hooks/usePropertyIdAndValuationYear";
import { timeFormatter } from "@/utils/string-formatting";

import { RichtextdatapointsFragment } from "../__generated__/privateTypes";
import { QueryHookValue } from "../types";

export type RichTextDataPoints = {
  vurderingsejendom_id?: number | null;
  deklarationsperiode_slutdato?: string;
  afgoerelsesperiode_slutdato?: string;
  vurderingstermindato?: string;
  seneste_deklaration_brevdato?: string;
  vurderingsaar?: number | null;
  vurderingsbrevdato?: string;
  grundvaerdi?: string | null;
  ejendomsvaerdi?: string | null;
  indsigelse_sendt?: string | null;
  bedste_anvendelse?: string | null;
  bedste_udnyttelse?: string | null;
  grundareal?: string | null;
  mindstegrundstoerrelse_valgt?: string | null;
  foreloebig_vaerdi_udstykket?: string | null;
  antal_mulige_udstykninger?: string | null;
  tba_brevdato?: string;
  gtba_brevdato?: string;
  gtbag_brevdato?: string;
  klagefrist_brevdato?: string;
  historisk_vurdering_klagefristbrev_brevdato?: string;
};

export const useRichtext = (): QueryHookValue<
  RichTextDataPoints | undefined
> => {
  const { valuationYear, propertyId } = usePropertyIdAndValuationYear();

  const { loading, data, error } = useRichText({ valuationYear, propertyId });

  const datapoints = React.useMemo<RichTextDataPoints | undefined>(() => {
    if (data) return getDatapointsFromData(data.vurderingsejendom);
  }, [data]);

  return {
    loading,
    error,
    data: datapoints,
  };
};

export const getDatapointsFromData = (
  vurderingsejendom: RichtextdatapointsFragment | null | undefined
) => {
  const declarations =
    vurderingsejendom?.vurderingssager?.[0]?.deklarationer ?? [];

  const latestDeclaration =
    declarations.length > 0
      ? declarations.filter(Boolean).sort((a, b) => {
          const x = a && a.brevdato && new Date(a.brevdato);
          const y = b && b.brevdato && new Date(b.brevdato);

          return y - x;
        })[0]
      : null;

  const tbaDate =
    vurderingsejendom?.tilbagebetalingssag?.tilbagebetaling?.filter(
      (doc) => doc?.dokumentgruppe === TBA_DOCUMENT
    )?.[0]?.brevdato ?? null;

  const gtbaDate =
    vurderingsejendom?.tilbagebetalingssag?.gentilbagebetaling?.filter(
      (doc) => doc?.dokumentgruppe === GTBA_DOCUMENT
    )?.[0]?.brevdato ?? null;

  const gtbagDate =
    vurderingsejendom?.tilbagebetalingssag?.tilbagebetaling?.filter(
      (doc) => doc?.dokumentgruppe === GTBAG_DOCUMENT
    )?.[0]?.brevdato ?? null;

  return {
    // DM + VM
    vurderingsejendom_id: vurderingsejendom?.vurderingsejendom_id ?? null,
    // DM
    deklarationsperiode_slutdato: timeFormatter(
      vurderingsejendom?.vurderingssager?.[0]?.sagsdata?.frister
        ?.deklarationsperiode_slutdato ?? null
    ),
    // DM
    afgoerelsesperiode_slutdato: timeFormatter(
      vurderingsejendom?.vurderingssager?.[0]?.sagsdata?.frister
        ?.afgoerelsesperiode_slutdato ?? null
    ),
    // DM + VM
    vurderingstermindato: timeFormatter(
      vurderingsejendom?.vurderingssager?.[0]?.sagsdata?.vurderingstermin
        ?.vurderingstermindato ?? null
    ),
    // DM + VM
    vurderingsaar:
      vurderingsejendom?.vurderingssager?.[0]?.sagsdata?.vurderingstermin
        ?.vurderingsaar ?? null,
    // VM
    vurderingsbrevdato: timeFormatter(
      vurderingsejendom?.vurderingssager?.[0]?.vurdering?.brevdato ?? null
    ),
    // VM
    grundvaerdi:
      vurderingsejendom?.vurderingssager?.[0]?.vurdering?.datagrundlag?.grund
        ?.grundvaerdi ?? null,
    // VM
    ejendomsvaerdi:
      vurderingsejendom?.vurderingssager?.[0]?.vurdering?.datagrundlag
        ?.ejendomsoplysninger?.ejendomsvaerdi ?? null,
    // DM
    indsigelse_sendt: timeFormatter(
      vurderingsejendom?.vurderingssager?.[0]
        ?.dato_for_sidst_indsendte_indsendelse ?? null
    ),
    // DM (GEN-DM)
    seneste_deklaration_brevdato: timeFormatter(
      latestDeclaration?.brevdato ?? null
    ),
    // VM
    bedste_anvendelse:
      vurderingsejendom?.vurderingssager?.[0]?.vurdering?.datagrundlag?.grund
        ?.grund_delvurderinger?.[0]?.bedste_anvendelse ?? null,
    // VM
    bedste_udnyttelse:
      vurderingsejendom?.vurderingssager?.[0]?.vurdering?.datagrundlag?.grund
        ?.grund_delvurderinger?.[0]?.bedste_udnyttelse ?? null,
    // VM
    grundareal:
      vurderingsejendom?.vurderingssager?.[0]?.vurdering?.datagrundlag
        ?.ejendomsoplysninger?.grundareal ?? null,
    // VM
    mindstegrundstoerrelse_valgt:
      vurderingsejendom?.vurderingssager?.[0]?.vurdering?.datagrundlag?.grund
        ?.grund_delvurderinger?.[0]?.mindstegrundstoerrelse_valgt ?? null,
    // VM
    foreloebig_vaerdi_udstykket:
      vurderingsejendom?.vurderingssager?.[0]?.vurdering?.datagrundlag?.grund
        ?.grund_delvurderinger?.[0]?.foreloebig_vaerdi_udstykket ?? null,
    //VM
    antal_mulige_udstykninger:
      vurderingsejendom?.vurderingssager?.[0]?.vurdering?.datagrundlag?.grund
        ?.grund_delvurderinger?.[0]?.antal_mulige_udstykninger ?? null,
    // TBA
    tba_brevdato: timeFormatter(tbaDate),
    // GTBA
    gtba_brevdato: timeFormatter(gtbaDate),
    // GTBA-G
    gtbag_brevdato: timeFormatter(gtbagDate),
    // KF (klagefrist_brevdato)
    klagefrist_brevdato: timeFormatter(
      vurderingsejendom?.vurderingssager?.[0]?.klagefristbrev?.brevdato ?? null
    ),
    // HV/KF (historisk vurdering/klagefristbrev_brevdato)
    historisk_vurdering_klagefristbrev_brevdato: timeFormatter(
      vurderingsejendom?.historisk_vurderingssag
        ?.historisk_vurdering_klagefristbrev?.brevdato ?? null
    ),
  };
};
